/*------------------------------------*\
  Elements > Page
\*------------------------------------*/

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  color: $text-color;
  background-color: $body-bg;
}

body {
  overflow-x: hidden;
}
