@mixin border-radius($size) {
  & {
    border-radius: $size !important;
  }

  &-lt, &-tl, &-left-top, &-top-left,
  &-l, &-left
  &-t, &-top {
    border-top-right-radius: $size !important;
  }
  &-rt, &-tr, &-right-top, &-top-right,
  &-r, &-right,
  &-t, &-top {
    border-top-left-radius: $size !important;
  }
  &-lb, &-bl, &-left-bottom, &-bottom-left,
  &-l, &-left
  &-b, &-bottom  {
    border-bottom-right-radius: $size !important;
  }
  &-rb, &-br, &-right-bottom, &-bottom-right,
  &-r, &-right,
  &-b, &-bottom  {
    border-bottom-left-radius: $size !important;
  }
}

.no-b-radius {
  @include border-radius(0);
}

.b-radius-2 {
  @include border-radius(2px);
}

.b-radius-4 {
  @include border-radius(4px);
}

.b-radius-6 {
  @include border-radius(6px);
}

.b-radius-100p, .rounded {
  @include border-radius(100%);
}
