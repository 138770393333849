/*------------------------------------*\
  Elements > Links
\*------------------------------------*/

a {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
