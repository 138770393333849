@import "~src/scss/1-settings/colors";

// width
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: color(gray-light);
}

// Track
::-webkit-scrollbar-track {
  background: transparent;
}

// Handle
::-webkit-scrollbar-thumb {
  background: color("purple");
  // background: rgba(#c8c6c4, 1);
  border-radius: 4px;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: rgba(#98a3a6, 1);
}
