.c-filter {
  width: 250px;
  select {
    -webkit-appearance: none; /* Remove estilo padrão do Chrome */
    -moz-appearance: none; /* Remove estilo padrão do FireFox */
    appearance: none; /* Remove estilo padrão do FireFox*/
    // background-image: url("~src/assets/icon/select-gray-arrow-down.svg");
    background-repeat: no-repeat;
    background-position-x: 224px;
    background-position-y: center;
    width: 100%;
    padding: 9px 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: rgb(129, 129, 129);
  }
}

@mixin c-checkbox($color-background, $color-text, $border-color, $active-border-color) {
  .c-checkbox-input {
    .c-checkbox {
      display: none;
      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $color-background;
        border: 1px solid $border-color;
        border-radius: 4px;
        transition: 0.1s ease-in;
      }

      &:hover + label:before {
        background-color: $color-text;
      }

      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.986);
      }

      &:checked + label:before {
        background: color(primary);
        border: 1px solid $active-border-color;
      }

      // Disabled state label.
      // &:disabled + label {
      //   color: #b8b8b8;
      //   cursor: auto;
      // }

      // Disabled box.
      // &:disabled + label:before {
      //     box-shadow: none;
      //     background: #ddd;
      // }

      &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: #fff;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
        transform: rotate(45deg);
      }
    }

    .c-checkbox-label {
      color: $color-text;
    }
  }
}

@mixin c-radio($color-background, $color-text, $border-color, $active-border-color) {
  .c-radio {
    margin-top: 13px;
    font-size: 13px;
    input {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $color-background;
        border: 1px solid $border-color;
        border-radius: 50%;
        transition: 0.1s ease-in;
      }

      &:hover + label:before {
        background-color: $color-text;
      }

      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.986);
      }

      &:checked + label:before {
        background: color(primary);
        border: 1px solid $active-border-color;
      }

      // Disabled state label.
      // &:disabled + label {
      //   color: #b8b8b8;
      //   cursor: auto;
      // }

      // Disabled box.
      // &:disabled + label:before {
      //     box-shadow: none;
      //     background: #ddd;
      // }

      &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: #fff;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
        transform: rotate(45deg);
      }
    }
  }
}

.ngb-dp-day {
  width: 1.7rem !important;
  height: 1.7rem !important;
}

.ngb-dp-day > div {
  width: 1.7rem !important;
  height: 1.7rem !important;
  line-height: 1.7rem !important;
}

.ngb-dp-weekday {
  width: 1.7rem !important;
}

// Removing input number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
