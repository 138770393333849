// Configurations
$spacer: 1rem;

$spacings: (
  0: 0,
  1: 0.25,
  2: 0.5,
  3: 1,
  4: 1.5,
  5: 3,
  "0-25": 0.25,
  "0-5": 0.5,
  "0-75": 0.75,
  "1-0": 1,
  "1-25": 1.25,
  "1-5": 1.5,
  "1-75": 1.75,
  "2-0": 2,
  "2-25": 2.25,
  "2-5": 2.5,
  "2-75": 2.75,
  "3-0": 3,
  "n0-25": -0.25,
  "n0-5": -0.5,
  "n0-75": -0.75,
  "n1-0": -1,
  "n1-25": -1.25,
  "n1-5": -1.5,
  "n1-75": -1.75,
  "n2-0": -2,
  "n2-25": -2.25,
  "n2-5": -2.5,
  "n2-75": -2.75,
  "n3-0": -3,
);

::ng-deep .mat-menu-content {
  min-width: 126px !important;
  width: auto !important;
}
