// *----------------------------------*\
//  Elements > Type
// *----------------------------------*/

html {
  font-family: "Open Sans";
}

.mat-slide-toggle-thumb {
  width: 12px !important;
  height: 12px !important;
  transform: translate(20%, 50%);
}

.mat-slide-toggle-bar {
  border-radius: 15px !important;
  height: 16px !important;
  width: 32px !important;
}

.mat-slide-toggle-thumb-container {
  top: -4px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #1dcc9b;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fff;
}
