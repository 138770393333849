$font-weight: (
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  normal,
  bold,
  lighter,
  bolder,
);
