// Extra bold
@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: normal;
  src: url("/assets/font/opensans/opensans-extra-bold.ttf") format("truetype");
}

// Extra bold
@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: italic;
  src: url("/assets/font/opensans/opensans-extra-bold-italic.ttf") format("truetype");
}

// Bold
@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/font/opensans/opensans-bold.ttf") format("truetype");
}

// Bold italic
@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
  src: url("/assets/font/opensans/opensans-bold-italic.ttf") format("truetype");
}

// Semi bold
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/font/opensans/opensans-semi-bold.ttf") format("truetype");
}

// Semi bold italic
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
  src: url("/assets/font/opensans/opensans-semi-bold-italic.ttf") format("truetype");
}

// Medium
@font-face {
  font-family: "Open Sans";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/font/opensans/opensans-regular.ttf") format("truetype");
}

// Medium italic
@font-face {
  font-family: "Open Sans";
  font-weight: 500;
  font-style: italic;
  src: url("/assets/font/opensans/opensans-italic.ttf") format("truetype");
}

// Regular
@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/font/opensans/opensans-regular.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: italic;
  src: url("/assets/font/opensans/opensans-italic.ttf") format("truetype");
}

// Light
@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/font/opensans/opensans-light.ttf") format("truetype");
}

// Light italic
@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: italic;
  src: url("/assets/font/opensans/opensans-light-italic.ttf") format("truetype");
}
