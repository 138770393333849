// Set the number of columns you want to use on your layout.
$fg-columns: 12;
// Set the gutter between columns.
$fg-gutter-width: rem(24px);
// Set a margin for the container sides.
$fg-outer-margin: rem(24px);
// Create or remove fg-breakpoints for your project
// Syntax:
// name SIZErem,
$fg-breakpoints:
  sm 48em,
  md 62em,
  lg 75em;

// .wrapper {
//   box-sizing: border-box;
//   max-width: 1200px;
//   margin: 0 auto;
// }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $fg-outer-margin;
  padding-left: $fg-outer-margin;
}

.d-flex,
[flex] {
  display: flex;
}

.row,
[f-row] {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -$fg-gutter-width / 2;
  margin-left: -$fg-gutter-width / 2;
}

.row.reverse,
[f-row-reverse] {
  flex-direction: row-reverse;
}

[class*="col"],
[f-col] {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $fg-gutter-width / 2;
  padding-left: $fg-gutter-width / 2;
}

@for $i from 1 through $fg-columns {
  .col-#{$i},
  [f-col*="#{$i}"] {
    flex-basis: 100% / $fg-columns * $i;
    max-width: 100% / $fg-columns * $i;
  }
}

@for $i from 1 through $fg-columns {
  .col-offset-#{$i},
  [f-col*="#{$i}"][f-col*="offset-#{$i}"] {
    margin-left: 100% / $fg-columns * $i
  }
}

.col,
[f-col] {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col.reverse,
[f-col-reverse] {
  flex-direction: column-reverse;
}

.col.auto {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.start,
[f-start] {
  justify-content: flex-start;
  text-align: start;
}

.center,
[f-center] {
  justify-content: center;
  text-align: center;
}

.end,
[f-end] {
  justify-content: flex-end;
  text-align: end;
}

.top,
[f-top] {
  align-items: flex-start;
}

.middle,
[f-middle] {
  align-items: center;
}

.bottom,
[f-bottom] {
  align-items: flex-end;
}

.around,
[f-around] {
  justify-content: space-around;
}

.between,
[f-between] {
  justify-content: space-between;
}

.first,
[f-first] {
  order: -1;
}

.last,
[f-last] {
  order: 1;
}

// Flex wrap - by default is flex wrap
.nowrap,
[f-nowrap] {
  flex-wrap: nowrap;
}

// Flex grow 1 to 5
.grow,
[f-grow] {
  flex-grow: 1;
}

@for $i from 0 through 5 {
  .grow-#{$i},
  [f-grow-#{$i}] {
    flex-grow: #{$i};
  }
}

// Flex shrink 0 to 5
@for $i from 0 through 5 {
  .shrink-#{$i},
  [f-shrink-#{$i}] {
    flex-shrink: #{$i};
  }
}

// Flex basis 1 to 100
@for $i from 1 through 100 {
  .basis-#{$i},
  [f-basis-#{$i}] {
    flex-basis: #{$i}#{"%"};
  }
}
