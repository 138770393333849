/*------------------------------------*\
  Generic > Reset
\*------------------------------------*/

/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
  * Remove trailing margins from nested lists.
  */
li > {
  ol,
  ul {
    margin-bottom: 0;
  }
}

/**
  * Remove default table spacing.
  */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  -webkit-user-drag: none;
  user-select: none;
}

*:disabled {
  cursor: not-allowed !important;
  user-select: none;
}

* {
  outline: none;

  &::first-letter {
    text-transform: uppercase;
  }
}
