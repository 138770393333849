// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-primary: mat.define-palette(mat.$indigo-palette);
$web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-theme: mat.define-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
      warn: $web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);

// *----------------------------------*\
//  Settings
// *----------------------------------*/

@import "1-settings/_border";
@import "1-settings/_breakpoints";
@import "1-settings/_colors";
@import "1-settings/_font-weight";
@import "1-settings/_shadows";
@import "1-settings/_spacing";
@import "1-settings/_typography";

// *----------------------------------*\
//  Tools
// *----------------------------------*/

@import "2-tools/_breakpoints";
@import "2-tools/_colors";
@import "2-tools/_helpers";
@import "2-tools/_shadows";
@import "2-tools/_spacing";

// *----------------------------------*\
//  Generic
// *----------------------------------*/

@import "3-generic/_box-sizing";
@import "3-generic/_normalize";
@import "3-generic/_shared";
@import "3-generic/_scrollbar";
@import "3-generic/_reset";

// *----------------------------------*\
//  Elements
// *----------------------------------*/

@import "4-elements/_links";
@import "4-elements/_page";
@import "4-elements/_type";
@import "4-elements/_table";
@import "4-elements/_p";

// *----------------------------------*\
//  Objects
// *----------------------------------*/

// @import '5-objects/_f-grid';
@import "5-objects/_flexboxgrid";

// *----------------------------------*\
//  Components
// *----------------------------------*/

@import "6-components/_button";
@import "6-components/_table-input";
@import "./6-components/inputs";
@import "./6-components/mat-form-field";

// *----------------------------------*\
//  Trumps
// *----------------------------------*/

@import "7-trumps/_borders";
@import "7-trumps/_colors";
@import "7-trumps/_font-weight";
@import "7-trumps/_shadows";
@import "7-trumps/_spacing";
@import "7-trumps/_text-transforms";

@import '~ngx-toastr/toastr';

button {
  outline: none;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.product-modal .modal-dialog {
  min-width: 1350px;
  padding: 27px 30px;
  border-radius: 8px;
}

.dp-input-container input {
  width: 100% !important;
  height: 48px !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}
